import { Preload } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";
import ReactDOM from "react-dom/client";
import * as THREE from "three";
import Experience from "./Experience.jsx";
import { PopUp } from "./Popup.jsx";
import "./style.css";

const root = ReactDOM.createRoot(document.querySelector("#root"));
const isMobile = window.innerWidth < 768;
root.render(
  <>
    <PopUp></PopUp>

    {isMobile ? (
      <div className="mobSafe">
        <img src="./images/changerSafe.png" alt="Mobile Image" />
      </div>
    ) : (
      <>
        <Canvas
          g
          l={{ toneMapping: THREE.NoToneMapping }}
          camera={{ fov: 10, far: 2000 }}
          dpr={[1, 1.5]}
        >
          <Experience />
          <Preload all />
        </Canvas>
      </>
    )}
  </>
);
