import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style.css';

export function PopUp() {
  const [open, setOpen] = useState(true);
  const [render, setRender] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
  });

  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) setOpen(false);
    };
    document.addEventListener('mousedown', handler);

    const timer = setTimeout(() => {
      if(document.readyState === "complete")
      setRender(true);
    }, 20000);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handler);
    };

  }, []);

  const popupClass = `pop-up ${open && render ? 'show' : 'hide'}`;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://changer-mailer.brain-socket.com/send-mail', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': window.navigator.language,
        },
      });

      const responseText = await response.text();
      if (response.ok) {
        console.log('Email sent successfully:', responseText);
      } else {
        console.error('Email sending failed:', responseText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const isMobile = window.innerWidth < 768;


  return (
    <div className={`pop-up-background ${open && render ? 'show' : 'hide'}`}>
      <div className="pop-up-container">
        <div ref={menuRef} className={popupClass}>
          <img
            className="close-btn"
            src="./images/close.svg"
            alt="close button"
            onClick={() => setOpen(false)}
          />
          <div className="pop-up-form">
            <h2>Want to learn more?</h2>
            <p>Drop us a line and we shall let you know all the updates</p>
            <form action="sendEmail.php" method="post" onSubmit={handleSubmit}>
              <div className="inputContainer">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="inputContainer">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="inputContainer">
                <PhoneInput
                  name="phone_number"
                  placeholder="Phone Number"
                  value={formData.phone_number}
                  onChange={(value) =>
                    setFormData({ ...formData, phone_number: value })
                  }
                  required
                />
              </div>
              <div className="inputContainer">
                <button className="pop-up-btn" type="submit" onClick={() => setOpen(false)}>
                  Submit
                </button>
              </div>
            </form>
          </div>
          {isMobile ? (
            <br></br>
          ) :
            (

              <div className="pop-up-img">
                <img
                  src="./images/64247b1dc705777b6db17df6_changer.ae-logo-White-3.svg"
                  alt="login page image"
                />
                <p >Changer.ae</p>

              </div>
            )}
        </div>
      </div>
    </div>
  );
}
